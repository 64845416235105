import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Homepage from './uiComponents/Homepage';

import Landing from './uiComponents/Landing';
import userLogin from './uiComponents/Login';
import Mywallet from './uiComponents/Mywallet';
import Addcase from './uiComponents/Addcase';

import Withdrawopt from './uiComponents/Withdrawopt';
import Profile1 from './uiComponents/Profile1';
import ViewGame1 from './uiComponents/ViewGame1';
import Gamehistory from './uiComponents/Gamehistory'
import 'animate.css';
import axios from 'axios';
import "./css/app2.css";
import Transactionhistory from './uiComponents/Transactionhistory';
import Referralhis from './uiComponents/Referralhis';
import Refer from './uiComponents/Refer';
import Notification from './uiComponents/Notification';
import Support from './uiComponents/Support';

// import Games from './uiComponents/Games';
// import Kyc from './uiComponents/Kyc';
import Kyc2 from './uiComponents/Kyc2';
// import kyc3 from './uiComponents/kyc3';
import RefundPolicy from './uiComponents/RefundPolicy';
import terms_condition from './uiComponents/terms_condition';
import PrivacyPolicy from './uiComponents/PrivacyPolicy';
import Gamerules from './uiComponents/Gamerules';
import ResponsibleGaming from './uiComponents/ResponsibleGaming';
import Return from './uiComponents/Return';
import Notify from './uiComponents/Notify';
import Header from './Components/Header';
import Rightcontainer from "./Components/Rightcontainer";
// import Downloadbutton from "./Components/Downloadbutton";
import Redeem from "./uiComponents/Redeem"
import AboutUs from './uiComponents/AboutUs';


const App2 = () => {
        //const [mount, setMount] = useState(true)
        //const history = useHistory()
        const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
        const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
        const nodeMode = process.env.NODE_ENV;
        if (nodeMode === "development") {
                var baseUrl = beckendLocalApiUrl;
        } else {
                baseUrl = beckendLiveApiUrl;
        }

        const access_token = localStorage.getItem("token")
        const [user, setUser] = useState()
        const getUser = () => {
                const headers = {
                        Authorization: `Bearer ${access_token}`
                }
                axios.get(baseUrl + `me`, { headers })
                        .then((res) => {
                                setUser(res.data)
                        }).catch((e) => {
                                if (e.response.status === 401) {
                                        localStorage.removeItem('token');
                                        // history.pushState("/login")
                                }
                        })
        }
        useEffect(() => {
                getUser()
                // eslint-disable-next-line 
        }, [])
        return (
                <>
                        <div className="leftContainer">
                                <div><Header user={user} /></div>
                        </div>

                        {!access_token ? <Switch>

                                <Route exact path="/" component={Landing} />
                                <Route path="/landing" component={Landing} />
                                <Route path="/login" component={userLogin} />
                                <Route path="/RefundPolicy" component={RefundPolicy} />
                                <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
                                <Route path="/term-condition" component={terms_condition} />
                                <Route path="/about" component={AboutUs} />
                                <Route path="/refund-policy" component={RefundPolicy} />
                                <Route path="/contact-us" component={Support} />
                                <Route path="/Gamerules" component={Gamerules} />
                                <Route path="/responsible-gaming" component={ResponsibleGaming} />
                                <Redirect to="/login" />

                        </Switch > :
                                <Switch>
                                        <Route path="/transaction-history" component={Transactionhistory} />
                                        {/* <Route exact path="/transaction" component={Transactionhistory} /> */}
                                        <Route exact path="/Referral-history" component={Referralhis} />
                                        <Route exact path="/landing" component={Landing} />
                                        <Route exact path="/Gamehistory" component={Gamehistory} />
                                        {/* <Route exact path="/profile" component={Profile} /> */}
                                        <Route exact path="/HomePage/:Game" render={() => <Homepage walletUpdate={getUser} />} />
                                        <Route exact path="/refer" component={Refer} />
                                        <Route exact path="/Notification" component={Notification} />
                                        <Route exact path="/" component={Landing} />
                                        <Route path="/profile" component={Profile1} />
                                        <Route path="/viewgame1/:id" component={ViewGame1} />
                                        <Route path="/addcase" render={() => <Addcase walletUpdate={getUser} />} />

                                        <Route path="/Withdrawopt" render={() => <Withdrawopt walletUpdate={getUser} />} />
                                        <Route path="/wallet" component={Mywallet} />
                                        <Route path="/support" component={Support} />

                                        {/* <Route path="/Games" component={Games} /> */}
                                        <Route exact path="/landing/:id" component={Landing} />
                                        <Route path="/kyc2" render={() => <Kyc2 user={user} />} />
                                        <Route path="/Rules" component={Gamerules} />
                                        <Route path="/RefundPolicy" component={RefundPolicy} />
                                        <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
                                        <Route path="/term-condition" component={terms_condition} />
                                        {/* <Route path="/timer" component={Timer}/> */}
                                        <Route path="/return" render={() => <Return walletUpdate={getUser} />} />
                                        <Route path="/notify" component={Notify} />

                                        <Route path="/Redeem" render={() => <Redeem walletUpdate={getUser} />} />
                                        <Route path="/contact-us" component={Support} />
                                        <Route path="/refund-policy" component={RefundPolicy} />
                                        <Route path="/Gamerules" component={Gamerules} />
                                        <Route path="/responsible-gaming" component={ResponsibleGaming} />
                                        <Route path="/about" component={AboutUs} />

                                        <Redirect to="/landing" />
                                </Switch>


                        }
                        <div className="rightContainer">
                                <Rightcontainer />
                        </div>




                          <a
                            href="https://api.whatsapp.com/send?phone=+919610646060&text=Hello"
                            class="float"
                            target="_blank"
                          >
                            
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="33"
                              height="33"
                              fill="currentColor"
                              class="bi bi-whatsapp  my-float"
                              viewBox="0 0 16 16"
                            >
                              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                            </svg>
                          </a>



                        {/* <h2>Install Demo</h2> */}
                </>
        )
}
export default App2;