// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:60px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:10px;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/app2.js/css/app2.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,UAAU;CACV,WAAW;CACX,WAAW;CACX,UAAU;CACV,wBAAwB;CACxB,UAAU;CACV,kBAAkB;CAClB,iBAAiB;EAChB,cAAc;CACf,4BAA4B;EAC3B,WAAW;AACb;;AAEA;CACC,eAAe;IACZ,YAAY;AAChB","sourcesContent":[".float{\n\tposition:fixed;\n\twidth:60px;\n\theight:60px;\n\tbottom:60px;\n\tright:20px;\n\tbackground-color:#25d366;\n\tcolor:#FFF;\n\tborder-radius:50px;\n\ttext-align:center;\n  font-size:30px;\n\tbox-shadow: 2px 2px 3px #999;\n  z-index:100;\n}\n\n.my-float{\n\tmargin-top:10px;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
